import { Badge, Icon, Tooltip, isValidIcon } from '../index';
import clsx from 'clsx';
import * as React from 'react';
import Select, {
  GroupBase,
  OptionProps,
  SingleValueProps,
  MultiValueProps,
  InputProps,
  MenuListProps,
  Props,
  components,
} from 'react-select';

const PORTAL_ELEMENT = document.querySelector('#portal') as HTMLElement;
import './style.scss';
import { ISelectOption } from './types';
import { isBoolean, isNil } from 'lodash-es';
export type SelectProps<
  IsMulti extends boolean,
  Option = unknown,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Pick<
  Props<Option, IsMulti, Group>,
  | 'isSearchable'
  | 'isClearable'
  | 'isDisabled'
  | 'isOptionDisabled'
  | 'isOptionSelected'
  | 'isLoading'
  | 'isMulti'
  | 'id'
  | 'onChange'
  | 'onInputChange'
  | 'defaultValue'
  | 'value'
  | 'placeholder'
  | 'classNamePrefix'
  | 'filterOption'
  | 'onFocus'
  | 'onMenuClose'
  | 'inputValue'
  | 'components'
  | 'className'
  | 'noOptionsMessage'
> & {
  additionalQueryKeys?: Array<string>;
  name: string;
  options: Array<Option | Group>;
  required?: boolean;
  isError?: boolean;
  size?: 'sm' | 'md';
  wrapItems?: boolean;
  withCheckboxes?: boolean;
  limit?: number;
  showCount?: boolean | ((total: number) => string);
  maxShown?: number;
} & React.RefAttributes<HTMLSelectElement>;

function CustomOption<
  IsMulti extends boolean,
  Option extends ISelectOption<string | number> = ISelectOption<
    string | number
  >,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: OptionProps<Option, IsMulti, Group> & {
    size: 'sm' | 'md';
  },
) {
  /* @ts-expect-error react-select doesn't provide a way extend selectProps in OptionProps  */
  const withCheckboxes = props.selectProps.withCheckboxes;
  return (
    <span
      // @ts-expect-error data-cy is not a valid attr
      data-cy={`${String(props.selectProps['data-cy'])}.${
        props.data.label || props.data.value
      }`}
      key={props.innerProps.key}
    >
      <components.Option
        {...props}
        className={'d-flex align-items-center space-x-2 position-relative'}
      >
        {props.isMulti && withCheckboxes && (
          <input
            type='checkbox'
            checked={props.isSelected}
            onChange={() => {}}
          />
        )}
        {isValidIcon(props.data.icon) ? (
          <Icon icon={props.data.icon} size={props.size} />
        ) : (
          props.data.icon
        )}
        <div
          style={{ width: '100%' }}
          className={clsx('react-select__option-text', props.size)}
        >
          {props.data.label}
        </div>
        {props.isSelected && !withCheckboxes && (
          <Icon
            icon='check-mark-line'
            size={'sm'}
            className='text-primary position-absolute'
            style={{ right: 5 }}
          />
        )}
      </components.Option>
    </span>
  );
}

function CustomSingleValueProps<
  IsMulti extends boolean,
  Option extends ISelectOption<string | number> = ISelectOption<
    string | number
  >,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: SingleValueProps<Option, IsMulti, Group>) {
  return (
    <components.SingleValue
      {...props}
      innerProps={{
        ...props.innerProps,
        // @ts-expect-error data-cy is not a valid attr
        'data-cy': `${String(props.selectProps['data-cy'])}.value`,
      }}
    />
  );
}
function CustomMultiValueProps<
  IsMulti extends boolean,
  Option extends ISelectOption<string | number> = ISelectOption<
    string | number
  >,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: MultiValueProps<Option, IsMulti, Group>) {
  // @ts-expect-error data-cy is not a valid attr
  const maxShown = (props.selectProps['maxShown'] as number | undefined) ?? 1;
  if (props.index < maxShown)
    return (
      <components.MultiValue
        {...props}
        innerProps={{
          ...props.innerProps,
          // @ts-expect-error data-cy is not a valid attr
          'data-cy': `${String(props.selectProps['data-cy'])}.${
            props.data.label
          }.value`,
        }}
      >
        {props.data.groupLabel && (
          <Badge pill bg='light-gray'>
            {props.data.groupLabel}
          </Badge>
        )}
        {props.children}
      </components.MultiValue>
    );
  if (props.index === maxShown) {
    const selectedValues = props.getValue();
    return (
      <Tooltip label={selectedValues.map((option) => option.label).join(',')}>
        <Badge pill bg='light-gray'>
          +{selectedValues.length - maxShown}
        </Badge>
      </Tooltip>
    );
  }
  return <></>;
}
function InnerCustomInputProps<
  IsMulti extends boolean,
  Option extends ISelectOption<string | number> = ISelectOption<
    string | number
  >,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: InputProps<Option, IsMulti, Group>) {
  return (
    <components.Input
      {...props}
      data-cy={`${props.selectProps.name as string}.input`}
    />
  );
}

function MenuList<
  IsMulti extends boolean,
  Option extends ISelectOption<string | number> = ISelectOption<
    string | number
  >,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ children, options, ...props }: MenuListProps<Option, IsMulti, Group>) {
  /* @ts-expect-error react-select doesn't provide a way extend selectProps in OptionProps  */
  const limit = props.selectProps?.limit as SelectProps<
    IsMulti,
    Option,
    Group
  >['limit'];
  /* @ts-expect-error react-select doesn't provide a way extend selectProps in OptionProps  */
  const shouldShowCount = props.selectProps?.showCount as SelectProps<
    IsMulti,
    Option,
    Group
  >['showCount'];
  const countEl = isBoolean(shouldShowCount)
    ? `${options.length} résultats`
    : shouldShowCount?.(options.length);
  return (
    <components.MenuList {...props} options={options}>
      <>
        {!isNil(limit) && Array.isArray(children)
          ? children.slice(0, limit)
          : children}
        {shouldShowCount && (
          <div className='px-2 bg-neutral-grey-1 text-neutral-grey-5'>
            {countEl}
          </div>
        )}
      </>
    </components.MenuList>
  );
}
const CustomInputProps = React.memo(
  InnerCustomInputProps,
) as typeof InnerCustomInputProps;

function ReactSelectWrapper<
  IsMulti extends boolean,
  Option extends ISelectOption<string | number> = ISelectOption<
    string | number
  >,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  { size = 'md', ...props }: SelectProps<IsMulti, Option, Group>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: React.Ref<any>,
) {
  return (
    <div className={'react-select-wrapper'}>
      <Select<Option, IsMulti, Group>
        {...props}
        onChange={(...args) => {
          props.onChange?.(...args);
        }}
        ref={ref}
        closeMenuOnSelect={!props.isMulti}
        classNamePrefix='react-select'
        hideSelectedOptions={!props.withCheckboxes && props.isMulti}
        menuPortalTarget={PORTAL_ELEMENT}
        classNames={{
          valueContainer: (controlProps) =>
            clsx(controlProps.className as string),
          dropdownIndicator: (controlProps) =>
            clsx(controlProps.className as string, size),
          control: (controlProps) =>
            clsx(controlProps.className as string, size, {
              'is-invalid': props.isError,
              'wrap-items': props.wrapItems,
            }),
          menuList: (controlProps) =>
            clsx(controlProps.className as string, size),
        }}
        components={{
          Option:
            props.components?.Option ||
            ((props) => <CustomOption {...props} size={size} />),
          SingleValue:
            props.components?.SingleValue ||
            ((props) => <CustomSingleValueProps {...props} />),
          MultiValue: (props) => <CustomMultiValueProps {...props} />,
          Input: CustomInputProps,
          MenuList: (props) => <MenuList {...props} />,
        }}
        styles={{
          menuPortal: (props) => ({
            ...props,
            zIndex: 1056, // should be greater than --falcon-modal-zindex (1055)
          }),
        }}
      />
    </div>
  );
}

export default React.forwardRef(
  ReactSelectWrapper,
) as typeof ReactSelectWrapper;
