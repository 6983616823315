import type {
  IFilter,
  IPaginationQuery,
  IPaginationResponse,
  SortOrder,
  WithRenderCallback,
} from '@wyz/types';
import clsx from 'clsx';
import { isEmpty, isFunction, isNil, pickBy } from 'lodash-es';

import * as React from 'react';
import { Table as BTable, Spinner } from 'react-bootstrap';

import {
  DatatableConfigurationContext,
  createDataTableContext,
  useDatatableContext,
  useGlobalConfiguration,
} from './context';
import DatatableErrorBoundary from './error-boundary';
import useDatatable from './hook';
import './style.scss';
import { TableCell } from './table-cell';
import {
  CellParams,
  ColumnDefinition,
  DatatableProps,
  IDatatableGlobalConfiguration,
  RowModel,
} from './types';
import { getRowIdentifier, isEqualCondition } from './utils';
import { useArray } from '@wyz/utils';
const getOrder = ({
  sort,
  field,
}: {
  field: string;
  sort: IPaginationQuery['sort'];
}): SortOrder | undefined => {
  const sortField = Object.keys(sort)[0];
  if (sortField !== field) return 'asc';
  else if (sort[sortField] === 'asc') return 'desc';
  return undefined;
};

type RenderPropData<TDataItem, TCriteria> = {
  data: IPaginationResponse<TDataItem> | undefined;
  criteria: TCriteria;
  isEmpty: boolean;
};
const DatatableProvider = <TDataItem extends RowModel, TCriteria = IFilter>({
  children,
  columns,
  fetcher,
  queryKey,
  defaults,
  translations,
  queryTransformer,
}: WithRenderCallback<
  Pick<
    DatatableProps<TDataItem, TCriteria>,
    | 'columns'
    | 'fetcher'
    | 'queryKey'
    | 'defaults'
    | 'translations'
    | 'queryTransformer'
  >,
  RenderPropData<TDataItem, TCriteria>
>): React.ReactElement => {
  const filterableColumns = columns.filter((column) => column.filterable);
  const datatable = useDatatable<TDataItem, TCriteria>({
    fetcher,
    queryTransformer,
    columns,
    queryKey,
    defaults: {
      ...defaults,
      criteria: {
        operator: 'and',
        conditions: filterableColumns.map((column) => {
          const defaultValue = defaults?.criteria?.conditions?.find(
            (condition) => isEqualCondition(condition, column),
          )?.value;
          return {
            column: getRowIdentifier(column),
            operator:
              column.filterable &&
              'isMulti' in column.filter &&
              column.filter.isMulti
                ? 'in'
                : 'eq',
            value: defaultValue,
          };
        }),
      },
    },
  });
  const Context = createDataTableContext<TDataItem, TCriteria>();
  const DEFAULT_KEYS = ['operator', 'conditions'];
  const isFiltered =
    Object.keys(
      pickBy(
        datatable.state.transformedCriteria || {},
        (value) => !isEmpty(value),
      ),
    ).filter((key) => !DEFAULT_KEYS.includes(key)).length > 0;

  return (
    <Context.Provider
      value={{ internals: datatable, columns, translations, defaults }}
    >
      {isFunction(children)
        ? children({
            data: datatable.query.data,
            criteria: datatable.state.transformedCriteria,
            isEmpty: datatable.query.data?.meta.total === 0 && !isFiltered,
          })
        : children}
    </Context.Provider>
  );
};

export const Table = <TDataItem extends RowModel>({
  containerStyles,
  stickyHeader,
  hoverable,
  keyGetter = (item) => item.id,
  children,
  renderExpanded,
}: React.PropsWithChildren<
  Pick<
    DatatableProps<TDataItem>,
    'containerStyles' | 'keyGetter' | 'hoverable'
  > & {
    stickyHeader?: boolean;
    renderExpanded?: (
      parameters: Pick<CellParams<TDataItem>, 'row'>,
    ) => React.ReactElement;
  }
>) => {
  const { internals, columns, translations } = useDatatableContext<TDataItem>();
  const [expandedRows, expandedRowsHandlers] = useArray<string>([]);

  const isLoading = internals.query.isLoading || internals.query.isFetching;
  const visibleColumns = columns.filter((column) => !column.hide);
  function handleSort(column: ColumnDefinition<TDataItem>) {
    const order = getOrder({
      sort: internals.state.sort,
      field: column.field,
    });
    internals.setter((prevState) => ({
      ...prevState,
      sort: isNil(order)
        ? {}
        : {
            [column.field]: order,
          },
    }));
  }

  function getIcon(column: ColumnDefinition<TDataItem>) {
    const sortField = Object.keys(internals.state.sort)[0];

    if (
      column.field === sortField &&
      internals.state.sort[sortField] === 'desc'
    )
      return <>&uarr;</>;
    else if (
      column.field === sortField &&
      internals.state.sort[sortField] === 'asc'
    )
      return <>&darr;</>;
    return undefined;
  }
  return (
    <div
      className={clsx('position-relative mb-0', {
        'overflow-auto': !stickyHeader,
      })}
      style={{
        ...containerStyles,
      }}
    >
      <BTable
        className={clsx('datatable overflow-auto', { loading: isLoading })}
        style={{ tableLayout: 'fixed' }}
      >
        <thead
          className={clsx('table-head text-center align-middle', {
            sticky: stickyHeader,
          })}
        >
          <tr>
            {visibleColumns.map((column) => (
              <th
                key={column.headerName}
                className={clsx({
                  'text-center': column.headerAlign === 'center',
                  'text-right': column.headerAlign === 'right',
                  'text-left': column.headerAlign === 'left',
                })}
                onClick={column.sortable ? () => handleSort(column) : undefined}
                {...(column.width && {
                  style: {
                    minWidth: column.width,
                    maxWidth: column.width,
                    width: column.width,
                  },
                })}
              >
                {column.sortable ? (
                  <div>
                    <span>{column.headerName}</span>
                    {getIcon(column)}
                  </div>
                ) : (
                  column.headerName
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='table-body'>
          {!isLoading && internals.query.data?.data?.length === 0 && (
            <tr>
              <td colSpan={visibleColumns.length} className='text-center'>
                {translations?.emptyMessageText ?? 'No data'}
              </td>
            </tr>
          )}
          {isLoading && (
            <tr>
              <td colSpan={visibleColumns.length} className='text-center'>
                <Spinner />
              </td>
            </tr>
          )}
          {(internals.query.data?.data || []).map((dataItem) => {
            const rowId = `row-${keyGetter(dataItem)}`;
            const isRowExpanded = expandedRows.includes(rowId);
            const close = () => expandedRowsHandlers.removeByItem(rowId);
            const open = () => expandedRowsHandlers.append(rowId);
            return (
              <React.Fragment key={rowId}>
                <tr className={clsx('align-middle', { hoverable: hoverable })}>
                  {visibleColumns.map((column) => {
                    return (
                      <TableCell
                        key={`${rowId}-${column.field}`}
                        dataItem={dataItem}
                        column={column}
                        expandability={{
                          close,
                          open,
                          toggle: isRowExpanded ? close : open,
                          isExpanded: isRowExpanded,
                        }}
                      />
                    );
                  })}
                </tr>
                {isRowExpanded && renderExpanded && (
                  <tr className='expanded'>
                    <td colSpan={visibleColumns.length}>
                      {renderExpanded({
                        row: dataItem,
                      })}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
          {children}
        </tbody>
      </BTable>
    </div>
  );
};

export const ConfigurationProvider = ({
  children,
  ...globals
}: React.PropsWithChildren<IDatatableGlobalConfiguration> = {}) => {
  return (
    <DatatableConfigurationContext.Provider value={globals}>
      {children}
    </DatatableConfigurationContext.Provider>
  );
};

export const Container = <TDataItem extends RowModel, TCriteria = IFilter>({
  children,
  ...props
}: WithRenderCallback<
  DatatableProps<TDataItem, TCriteria>,
  RenderPropData<TDataItem, TCriteria>
>): React.ReactElement => {
  const globalConfig = useGlobalConfiguration();
  return (
    <DatatableErrorBoundary containerStyles={props.containerStyles}>
      <DatatableProvider<TDataItem, TCriteria>
        {...props}
        translations={{ ...globalConfig.translations, ...props.translations }}
      >
        {(state) => <>{isFunction(children) ? children(state) : children}</>}
      </DatatableProvider>
    </DatatableErrorBoundary>
  );
};
