import type { IFilterCondition } from '@wyz/types';
import {
  isArrayOfOption,
  isOption,
  parseFormData,
  serializeFormData,
  useOnMount,
} from '@wyz/utils';
import { isEmpty, orderBy, set } from 'lodash-es';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Button, Col, Flex, FormContainer, Icon, TextField } from '../index';
import { useDatatableContext } from './context';
import FILTERS from './filters/index';
import {
  ColSpanDefinition,
  ColumnDefinition,
  IFilterForm,
  RowModel,
} from './types';
import { getRowIdentifier, isEqualCondition } from './utils';

const formatDefaultValues = <TDataItem extends RowModel>(
  columns: Array<ColumnDefinition<TDataItem>>,
  conditions: IFilterCondition[] = [],
) => {
  return columns.reduce(
    (acc, column) => {
      if (!column.filterable) {
        return acc;
      }
      const defaultValue = conditions.find((condition) =>
        isEqualCondition(condition, column),
      )?.value;
      if (
        column.filterable &&
        isArrayOfOption(defaultValue) &&
        column.filter.type === 'LIST' &&
        column.filter.isMulti
      ) {
        set(
          acc,
          getRowIdentifier(column),
          defaultValue.map((item) => item.value),
        );
      } else if (
        column.filterable &&
        isOption(defaultValue) &&
        column.filter.type === 'LIST' &&
        !column.filter.isMulti
      ) {
        set(acc, getRowIdentifier(column), defaultValue.value);
      } else {
        set(acc, getRowIdentifier(column), defaultValue);
      }
      return acc;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {} as Record<string, any>,
  );
};

type Props = {
  queryColSpan?: ColSpanDefinition;
  qsAware?: boolean;
};

const DatatableFilter = <TDataItem extends RowModel>({
  queryColSpan,
  qsAware,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const datatableContext = useDatatableContext<TDataItem>();
  const filterableColumns = datatableContext.columns.filter(
    (column) => column.filterable,
  );
  const [defaultValues] = useState(() =>
    qsAware
      ? parseFormData(searchParams.toString())
      : formatDefaultValues(
          filterableColumns,
          datatableContext.defaults?.criteria?.conditions,
        ),
  );

  const methods = useForm<IFilterForm>({
    defaultValues: defaultValues,
  });

  useOnMount(() => {
    if (qsAware && !isEmpty(defaultValues)) {
      datatableContext.internals.setter(
        datatableContext.internals.preSubmit(defaultValues),
      );
    }
  });

  return (
    <div>
      <FormContainer
        methods={methods}
        submitHandler={(formData) => {
          if (qsAware) {
            setSearchParams(serializeFormData(formData));
          }
          datatableContext.internals.setter(
            datatableContext.internals.preSubmit(formData),
          );
        }}
      >
        <div className='grid gap-1'>
          <Col
            bsPrefix='g-col'
            xs={queryColSpan?.xs || 6}
            sm={queryColSpan?.sm || 6}
            md={queryColSpan?.md || 6}
            lg={queryColSpan?.lg || 4}
            xl={queryColSpan?.xl || 3}
            xxl={queryColSpan?.xxl || 2}
          >
            <TextField
              translate={(key) => key}
              label={datatableContext.translations?.searchLabel}
              placeholder={
                datatableContext.translations?.searchPlaceholder === undefined
                  ? 'Type anything ...'
                  : datatableContext.translations?.searchPlaceholder ?? ''
              }
              type='search'
              name='searchQuery'
            />
          </Col>
          {orderBy(filterableColumns, 'filter.rank').map((filterableColumn) => {
            if (!filterableColumn.filterable) {
              return undefined;
            }
            const defaultValue =
              datatableContext.defaults?.criteria?.conditions?.find(
                (condition) => isEqualCondition(condition, filterableColumn),
              )?.value;
            const isMulti =
              (filterableColumn.filterable &&
                'isMulti' in filterableColumn.filter &&
                filterableColumn.filter.isMulti) ??
              false;

            const Component = FILTERS[filterableColumn.filter.type];
            const commonProps = {
              ...filterableColumn.filter,
              name: getRowIdentifier(filterableColumn),
              label: filterableColumn.headerName,
              defaultValue: defaultValue,
            };
            const customProps =
              filterableColumn.filter.type === 'LIST' ||
              filterableColumn.filter.type === 'TYPEAHEAD'
                ? { isMulti, isClearable: true }
                : {};
            return (
              <Col
                bsPrefix='g-col'
                key={filterableColumn.headerName}
                xs={filterableColumn.filterColSpan?.xs || 6}
                sm={filterableColumn.filterColSpan?.sm || 6}
                md={filterableColumn.filterColSpan?.md || 6}
                lg={filterableColumn.filterColSpan?.lg || 4}
                xl={filterableColumn.filterColSpan?.xl || 2}
                xxl={filterableColumn.filterColSpan?.xxl || 2}
              >
                <Component {...commonProps} {...customProps} />
              </Col>
            );
          })}
          {filterableColumns.length > 0 && (
            <Col
              bsPrefix='g-col'
              className='justify-end d-flex align-items-center mt-3'
              xl={3}
              lg={4}
            >
              <Flex alignItems='center'>
                <Button
                  id='reset-filters'
                  variant='link'
                  type='button'
                  size='xs'
                  className='ml-2'
                  onClick={() => {
                    methods.reset();
                    datatableContext.internals.setter(
                      datatableContext.internals.preSubmit(
                        methods.formState.defaultValues,
                      ),
                    );
                  }}
                >
                  <Icon icon='close-circle' size='xxs' />
                  {datatableContext.translations?.resetPlaceholder ?? 'Reset'}
                </Button>
                <Button
                  id='submit-filters'
                  variant='tertiary-blue'
                  size='xs'
                  type='submit'
                >
                  {datatableContext.translations?.searchBtn ?? 'Rechercher'}
                </Button>
              </Flex>
            </Col>
          )}
        </div>
      </FormContainer>
    </div>
  );
};
export default DatatableFilter;
